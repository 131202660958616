import { getUserAuthHeader } from '../lib/schema/user/client-fetch';
import { useAuth } from './useAuth';

export const useRecentSearch = () => {
  const { user } = useAuth();
  const handleSaveRecentSearch = async (input: string) => {
    if (input) {
      if (!user) {
        const recentSearchArray = JSON.parse(
          sessionStorage.getItem('recentSearches') || '[]'
        );
        if (!recentSearchArray.includes(input)) {
          if (recentSearchArray.length === 3) {
            recentSearchArray.shift();
          }
          recentSearchArray.push(input);
        }
        sessionStorage.setItem(
          'recentSearches',
          JSON.stringify(recentSearchArray)
        );
      } else {
        const authHeader = await getUserAuthHeader();
        if (authHeader) {
          const response = await fetch(
            `/api/user/recentSearch?search_query=${input}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
              },
            }
          );
          if (!response.ok) {
            throw new Error('unable to save user recent search');
          }
        }
      }
    }
  };

  const handleGetRecentSearch = async () => {
    if (user) {
      const authHeader = await getUserAuthHeader();
      if (authHeader) {
        const response = await fetch('/api/user/recentSearch', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader,
          },
        });
        if (response.ok) {
          const savedRecentSearches = await response.json();
          return savedRecentSearches;
        }
      }
    }
    const cachedRecentSearches = sessionStorage.getItem('recentSearches');
    return cachedRecentSearches ? JSON.parse(cachedRecentSearches) : [];
  };

  const handleRemoveRecentSearch = async (input: string) => {
    if (input) {
      if (!user) {
        const recentSearchArray = JSON.parse(
          sessionStorage.getItem('recentSearches') || '[]'
        );
        const latestSearchArray = recentSearchArray.filter(
          (item: string) => item !== input
        );

        sessionStorage.setItem(
          'recentSearches',
          JSON.stringify(latestSearchArray)
        );
      } else {
        const authHeader = await getUserAuthHeader();
        if (authHeader) {
          const response = await fetch(
            `/api/user/recentSearch?search_query=${input}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader,
              },
            }
          );
          if (!response.ok) {
            throw new Error('unable to remove user recent search');
          }
        }
      }
    }
  };

  return {
    handleSaveRecentSearch,
    handleGetRecentSearch,
    handleRemoveRecentSearch,
  };
};
