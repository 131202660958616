import { Input, InputProps } from '@/components/atoms/Input/Input';
import { stringMatch } from '@/lib/searchAlgorithms';
import { FilterType } from '@/types/filters';
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import DropdownMenu, {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
} from '../DropdownMenu';
import { DropdownMenuItemVariants } from '../DropdownMenuItem';

interface SearchBarProps
  extends Pick<InputProps, 'placeholder' | 'append' | 'prepend'> {
  makes: string[];
  models: { [key: string]: string[] };
  input: string;
  setInput: (input: string) => void;
  handleVectorSearch?: (input: string) => void;
  filters?: FilterType;
  recentSearch: string[];
  onClickAppend?: () => void;
  dropDownClassName?: string;
  limitSuggestions?: boolean;
  alwaysShowOptions?: boolean;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  makes,
  models,
  input = '',
  setInput,
  placeholder = 'Search by make or model',
  append,
  prepend,
  handleVectorSearch = () => {},
  filters,
  recentSearch,
  onClickAppend,
  dropDownClassName,
  limitSuggestions = true,
  alwaysShowOptions,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownMenuOptionType[] | DropdownMenuGroupType[]
  >([]);

  useEffect(() => {
    if (filters?.vector_search === '') {
      setInput('');
    }
  }, [filters?.vector_search, setInput]);

  const getSuggestions = useCallback(
    (
      newInput: string,
      makesList: string[],
      modelsList: { [key: string]: string[] }
    ) => {
      let finalMenuItems = [];

      finalMenuItems = stringMatch(
        newInput.toLowerCase(),
        makesList,
        modelsList
      );

      if (limitSuggestions) {
        finalMenuItems = finalMenuItems.slice(0, 5);
      }

      setDropdownOptions(finalMenuItems);
    },
    [limitSuggestions]
  );

  const handleChangeInput = useCallback(
    (newInput: string) => {
      setInput(newInput);
      getSuggestions(newInput, makes, models);
    },
    [makes, models, setInput, getSuggestions]
  );

  const handleDropdownSelect = useCallback(
    (selected: string) => {
      setInput(selected);
      handleVectorSearch(selected);
    },
    [setInput, handleVectorSearch]
  );

  const handleEnter = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Enter' && input !== filters?.vector_search) {
        handleVectorSearch(input);
        setShowOptions(false);
        e.preventDefault();
      }
    },
    [filters?.vector_search, handleVectorSearch, input]
  );

  return (
    <div ref={containerRef} className="relative flex w-full">
      <div ref={inputRef} className="flex-grow">
        <Input
          onKeyDown={handleEnter}
          aria-label="Search bar"
          id="search-bar"
          placeholder={placeholder}
          value={input}
          onChange={(e) => handleChangeInput(e)}
          autoComplete="off"
          append={append}
          prepend={prepend}
          onClickAppend={onClickAppend}
          onFocus={() => setShowOptions(true)}
          onBlur={() => {
            setShowOptions(false);
            handleVectorSearch(input);
          }}
        />
      </div>

      <div className="absolute top-[100%] flex w-full">
        <DropdownMenu
          input={input}
          open={alwaysShowOptions || showOptions}
          className={`${
            alwaysShowOptions || showOptions ? 'flex flex-grow' : 'hidden'
          } ${dropDownClassName}`}
          options={dropdownOptions}
          value={[]}
          onChange={(val) => {
            handleDropdownSelect(val[0]);
          }}
          variant={DropdownMenuItemVariants.NoSelect}
          recentSearch={recentSearch}
        />
      </div>
    </div>
  );
};
